<template>
  <div class="avatar">
    <Camera
      v-if="showCam"
      :stationData="stationData"
      v-on:close="showCam = false"
      v-on:postImage="handleImage"
    ></Camera>
    <div class="content">
      <div
        class="avatar-preview"
        v-bind:style="{ backgroundImage: avatarImage }"
      >
        <a href="#" @click.prevent="showCam = true" class="cam-btn"></a>
      </div>
    </div>
    <a v-if="!started" href="#" class="btn-start btn-big" @click.prevent="start"
      >{{ $t('start') }}</a
    >
    <div class="lds-ripple" v-if="started">
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import Camera from './Camera';

export default {
  name: 'Avatar',
  props: ['stationData'],
  components: {
    Camera,
  },
  data: () => {
    return {
      showCam: false,
      imageData: null,
      started: false,
    };
  },
  mounted() {
    this.playAudio();
  },
  computed: {
    avatarImage: function () {
      if (this.imageData) {
        return 'url(' + this.imageData + ')';
      }
      return null;
    },
  },
  methods: {
    playAudio() {
      window.setTimeout(() => {
        this.sound = new Howl({
          src: [this.stationData.voiceover_avatar],
          autoplay: true,
        });
      }, 500);
    },
    start() {
      this.started = true;
      if (this.imageData) {
        this.$emit('setAvatar', this.imageData);
      }
      this.sound = new Howl({
        src: [this.stationData.voiceover_start],
        autoplay: true,
        onend: () => {
          this.$emit('end');
        },
      });
    },
    handleImage(imageData) {
      this.imageData = imageData;
    },
  },
};
</script>

<style scoped lang="scss">
.avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.content {
  padding: 4rem;
  padding-top: 6rem;
}
.avatar-preview {
  width: 17rem;
  height: 17rem;
  background-image: url('../assets/images/avatar-default.png');
  background-size: 100%;
  border-radius: 99%;
  margin: 0 auto 0 auto;
  position: relative;
}

.cam-btn {
  display: block;
  width: 5rem;
  height: 5rem;
  background-color: var(--black);
  position: absolute;
  bottom: -2.5rem;
  left: 50%;
  transform: translateX(-50%);
  background-image: url('../assets/images/icon-camera.svg');
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 99%;
}

.btn-start {
  position: absolute;
  bottom: 4rem;
  left: 4rem;
  width: calc(100% - 8rem);
  margin: 0;
}

.lds-ripple {
  display: block;
  position: relative;
  width: 200px;
  height: 200px;
  position: absolute;
  bottom: 4rem;
  left: 50%;
  margin-left: -100px;
  z-index: 2;
}
.lds-ripple div {
  position: absolute;
  border: 8px solid #fff;
  margin-left: -8px;
  margin-top: -8px;
  opacity: 1;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: lds-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
</style>
