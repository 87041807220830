<template>
  <div class="intro">
    <div class="hero">
      <img
        class="hero__birdlife"
        :src="require('../assets/images/birdlife-neu.png')"
        alt="BirdLife Schweiz"
      />
      <h1>{{ $t('singenwiedievoegel') }}</h1>
    </div>
    <div class="content">
      <p>
        {{ $t('naturzentrum') }}
        <br />
        {{ $t('ausstellung') }}
      </p>
    </div>
    <a
      class="btn-start btn-big"
      href="#"
      @click.prevent="$emit('navigate', 'AudioIntro')"
    >
      {{ $t('start') }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'Intro',
  props: [],
  methods: {},
};
</script>

<style scoped lang="scss">
.intro {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.hero {
  background-color: var(--white);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  z-index: 1;
  text-align: left;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 10vh;
    left: 16rem;
    width: 14rem;
    height: 11rem;
    background-image: url('../assets/images/bird-head.png');
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
  }

  h1 {
    font-size: 3rem;
    position: absolute;
    left: 4rem;
    bottom: 2rem;
    max-width: 17rem;
    line-height: 1.1;
    margin: 0;
  }
  &__birdlife {
    width: 4rem;
    height: auto;
    position: absolute;
    left: 4rem;
    top: 2rem;
  }
}
.content {
  padding: 4rem;
  padding-top: calc(40vh + 2rem);
  font-family: 'Syntax';
  font-size: 1rem;
}
p {
  margin: 0;
}
.btn-start {
  position: absolute;
  bottom: 4rem;
  left: 4rem;
  width: calc(100% - 8rem);
  margin: 0;
}
</style>
