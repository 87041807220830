<template>
  <div class="start">
    <!-- <span class="bird"></span> -->
    <div class="lang-btns">
      <a class="btn-big" href="#" @click.prevent="setLang('de')">Deutsch</a>
      <a class="btn-big" href="#" @click.prevent="setLang('fr')">Français</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Start',
  props: [],
  methods: {
    setLang(lang) {
      this.$i18n.locale = lang;
      this.$emit('setLang', lang);
      this.$emit('navigate', 'Intro');
    },
  },
};
</script>

<style scoped lang="scss">
.start {
  // background-color: var(--white);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lang-btns {
  width: 100%;
  padding: 0 4rem;
  z-index: 1;
}
.bird {
  display: block;
  position: absolute;
  top: 5%;
  left: 0;
  width: 100%;
  height: 30%;
  background-image: url('../assets/images/bird-head.png');
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
