<template>
  <div v-bind:class="{ live: showFeed, camera: true }">
    <a href="#" @click.prevent="$emit('close')" class="close-btn"></a>
    <div class="cam-wrap">
      <span v-if="!camReady" class="loader"></span>
      <div class="canvas-wrap" v-bind:class="{ shutteranim: !showFeed }">
        <video
          v-bind:class="{
            visible: camReady,
            active: showFeed,
            portrait: camIsPortrait,
          }"
          autoplay
          id="camvideo"
          class="camvideo"
          ref="camvideo"
        ></video
        >^
      </div>
      <a
        href="#"
        v-if="showFeed"
        @click.prevent="takePicture"
        class="cam-btn cam-btn--trigger"
      ></a>
      <a
        href="#"
        v-if="!showFeed"
        @click.prevent="resetCam"
        class="cam-btn cam-btn--reset"
      ></a>
    </div>
    <div ref="p5" class="p5vue"></div>

    <a
      v-if="!picTaken"
      href="#"
      @click.prevent="$emit('close')"
      class="ok-btn btn-big"
      >{{ $t('cancel') }}</a
    >
    <a
      v-if="picTaken"
      href="#"
      @click.prevent="confirm"
      class="ok-btn btn-big"
      >{{ $t('save') }}</a
    >
  </div>
</template>

<script>
import p5 from 'p5';
import Hammer from 'hammerjs';

const icons = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
];

export default {
  name: 'Camera',
  props: ['stationData'],
  data: () => {
    return {
      showFeed: true,
      camReady: false,
      camIsPortrait: false,
      videoSize: [0, 0],
      picTaken: false,
      icons: [...icons],
      iconsToShow: 8,
    };
  },
  mounted() {
    this.initCam();
    this.initCanvas();
  },
  methods: {
    initCam() {
      this.vid = this.$refs.camvideo;

      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            this.vid.srcObject = stream;
            this.vid.play();
            this.vid.addEventListener('loadedmetadata', () => {
              this.resizeCam();
              this.camReady = true;
            });
          })
          .catch((error) => {
            console.log(error);
            console.log('Something went wrong!');
          });
      }
    },
    resizeCam() {
      this.videoSize = [this.vid.videoWidth, this.vid.videoHeight];
      if (this.videoSize[0] < this.videoSize[1]) {
        this.camIsPortrait = true;
      }
    },
    initCanvas() {
      this.tempCanvas = document.createElement('canvas');

      this.tempCanvas.width = 400;
      this.tempCanvas.height = 400;
      this.tempCtx = this.tempCanvas.getContext('2d');
    },
    playAudio() {
      this.sound = new Howl({
        src: [this.stationData.voiceover_cam],
        autoplay: true,
      });
    },
    takePicture() {
      if (!this.picTaken) {
        this.playAudio();
      }
      this.picTaken = true;

      this.tempCtx.save();
      this.tempCtx.beginPath();
      this.tempCtx.arc(200, 200, 200, 0, Math.PI * 2, false);
      this.tempCtx.clip();

      if (this.camIsPortrait) {
        let ratio = this.videoSize[1] / this.videoSize[0];
        let newHeight = this.tempCanvas.height * ratio;
        let margin = (newHeight - this.tempCanvas.width) / -2;
        this.tempCtx.drawImage(
          this.vid,
          0,
          margin,
          this.tempCanvas.width,
          newHeight
        );
      } else {
        let ratio = this.videoSize[0] / this.videoSize[1];
        let newWidth = this.tempCanvas.width * ratio;
        let margin = (newWidth - this.tempCanvas.height) / -2;
        this.tempCtx.drawImage(
          this.vid,
          margin,
          0,
          newWidth,
          this.tempCanvas.height
        );
      }
      this.tempCtx.restore();

      this.showFeed = false;

      this.initIcons();
    },

    initIcons() {
      this.uid = `p5-${Math.round(Math.random() * 10000)}`;
      this.$refs.p5.innerHTML = '';
      this.p5 = new p5((p) => {
        this.iconSketch(p, this);
      }, this.$refs.p5);
    },

    iconSketch(p, self) {
      let canvas,
        imgs,
        hammer = new Hammer(document.body, { preventDefault: true });

      function setActiveImgs() {
        imgs.forEach((img) => {
          img.active = false;
        });
        let lastFoundIndex = null;
        for (let i = 0; i < imgs.length; i++) {
          let img = imgs[i];
          if (
            p.mouseX > img.x - img.width * 0.5 &&
            p.mouseX < img.x + img.width * 0.5 &&
            p.mouseY > img.y - img.height * 0.5 &&
            p.mouseY < img.y + img.height * 0.5
          ) {
            if (lastFoundIndex === null) {
              lastFoundIndex = i;
            }
            imgs[i].active = true;
          }
        }
        return lastFoundIndex;
      }

      function loadImages(eq) {
        const icons = [...self.icons];
        let loadedIcons = [];

        for (let i = 0; i < eq; i++) {
          const randomIcon = Math.floor(Math.random() * icons.length);
          const removed = icons.splice(randomIcon, 1);
          loadedIcons.push(
            p.loadImage('/stickers/' + removed[0] + '.png', (img) => {
              imgs[i].height = img.height * 0.4;
              imgs[i].width = img.width * 0.4;
            })
          );
        }

        return loadedIcons;
      }

      const w = window.innerWidth;
      const h = window.innerHeight;

      const startPositions = [
        [h * 0.2, w * 0.1],
        [h * 0.1, w * 0.5],
        [h * 0.2, w * 0.9],
        [h * 0.5, w * 0.1],
        [h * 0.5, w * 0.9],
        [h * 0.7, w * 0.1],
        [h * 0.8, w * 0.5],
        [h * 0.7, w * 0.9],
      ];

      p.setup = function () {
        p.pixelDensity(1);

        p.angleMode(p.DEGREES);
        p.imageMode(p.CENTER);
        p.rectMode(p.CENTER);
        canvas = p.createCanvas(w, h);

        imgs = loadImages(self.iconsToShow).map((img, i) => {
          return {
            img: img,
            y: startPositions[i][0],
            x: startPositions[i][1],
            height: 0, //img.height * .5,
            width: 0, //img.width * .5,
            active: false,
            rotation: 0,
          };
        });
      };

      p.mouseDragged = function () {
        for (let i = 0; i < imgs.length; i++) {
          if (imgs[i].active) {
            imgs[i].x = p.mouseX;
            imgs[i].y = p.mouseY;
            break;
          }
        }
      };

      p.mousePressed = function () {
        let lastFoundIndex = setActiveImgs();

        if (lastFoundIndex !== null) {
          let lastImg = imgs[lastFoundIndex];
          imgs.splice(lastFoundIndex, 1);
          imgs.push(lastImg);
        }
      };

      hammer.get('pinch').set({ enable: true });
      hammer.get('rotate').set({ enable: true });

      /*hammer.on("pinch", (e) => {
        for (let i = 0; i < imgs.length; i++) {
          if (imgs[i].active) {
            let scale = e.scale > 1 ? e.scale * 1.8 : e.scale * .2;  
            imgs[i].height *= scale;
            imgs[i].width *= scale;
            break;
          }
        }
      });*/

      hammer.on('rotate', (e) => {
        for (let i = 0; i < imgs.length; i++) {
          if (imgs[i].active) {
            imgs[i].rotation = p.radians(e.rotation);
            break;
          }
        }
      });

      let camImage = p.loadImage(self.tempCanvas.toDataURL('image/png'));
      // let camBoundingBox = self.canvas.getBoundingClientRect();

      p.draw = function () {
        p.clear();
        p.image(camImage, w * 0.5, h * 0.5 - 100, 400, 400);

        imgs.forEach((img, i) => {
          p.rotate(img.rotation);
          p.image(img.img, img.x, img.y, img.width, img.height);
          p.rotate(0);
        });
      };
    },
    resetCam() {
      this.showFeed = true;
      this.initCam();
    },
    confirm() {
      let p5canvas = this.$refs.p5.querySelector('canvas');

      let imageData = p5canvas.toDataURL('image/png');

      this.tempCtx.save();
      this.tempCtx.beginPath();
      this.tempCtx.arc(200, 200, 200, 0, Math.PI * 2, false);
      this.tempCtx.clip();
      this.tempCtx.drawImage(
        p5canvas,
        -(p5canvas.width / 2 - 200),
        -(p5canvas.height / 2 - 300)
      );
      this.tempCtx.restore();
      // this.tempCtx.globalCompositeOperation = "destination-over";
      imageData = this.tempCanvas.toDataURL('image/png');

      let tempImg = new Image();
      tempImg.src = imageData;
      tempImg.onload = () => {
        this.tempCtx.fillStyle = '#ffffff';
        this.tempCtx.fillRect(0, 0, 400, 400);
        this.tempCtx.drawImage(tempImg, 0, 0);

        imageData = this.tempCanvas.toDataURL('image/png');
        this.$emit('postImage', imageData);
        this.$emit('close');
      };
    },
  },
};
</script>

<style scoped lang="scss">
.camera {
  position: absolute;
  background-color: var(--white);
  top: 0rem;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}
.content {
  padding: 2rem;
  padding-top: 36rem;
  text-align: center;
}
.cam-wrap {
  position: absolute;
  top: 50%;
  left: 0;
  height: 400px;
  width: 100%;
  margin-top: -300px;
}
.canvas-wrap {
  position: relative;
  margin: 0 auto;
  top: 0;
  width: 400px;
  height: 400px;
  overflow: hidden;
  border-radius: 99%;
  // border: 4px solid #000;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--white);
    z-index: 1;
    opacity: 1;
    transition: 1s opacity;
  }

  &.shutteranim {
    &::before {
      opacity: 0;
    }
  }
}

.camvideo {
  position: absolute;
  top: 0;
  left: 50%;
  width: auto;
  height: 400px;
  z-index: 2;
  transform: translateX(-50%);
  display: none;
  background-color: #fff;
  opacity: 0;
  transition: 0.5s opacity;

  &.active {
    opacity: 1;
  }
  &.visible {
    display: block;
  }
  &.portrait {
    width: 400px;
    height: auto;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.close-btn {
  display: block;
  width: 3rem;
  height: 3rem;
  background-color: var(--black);
  position: absolute;
  top: 0;
  right: 0;
  background-image: url('../assets/images/icon-close.svg');
  background-repeat: no-repeat;
  background-position: center;
  z-index: 3;
}
.cam-btn {
  display: block;
  width: 4rem;
  height: 4rem;
  margin-left: -2rem;
  position: absolute;
  bottom: -2rem;
  left: 50%;
  background-color: var(--black);
  background-size: 100%;
  z-index: 4;
  border-radius: 99%;
  color: #fff;
  text-align: center;
  line-height: 6rem;
  font-size: 3rem;

  &--trigger {
    background-image: url('../assets/images/icon-trigger.png');
  }
  &--reset {
    background-image: url('../assets/images/icon-reset.png');
  }
}
.ok-btn {
  position: absolute;
  width: calc(100% - 4rem);
  bottom: 1rem;
  left: 2rem;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  display: block;
  width: 400px;
  height: 400px;
  margin-left: -200px;
  margin-top: -200px;
  box-sizing: border-box;
  border: 5px solid rgba(#212121, 0.1);
  border-top-color: rgba(#212121, 0.3);
  border-radius: 99%;
  animation: loaderspin 1s infinite linear;
}
@keyframes loaderspin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.p5vue {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  .live & {
    display: none;
  }
}
</style>
