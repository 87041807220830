<template>
  <div id="app">
    <div v-if="showHeader" class="header">{{ $t('singenwiedievoegel') }}</div>
    <div class="component-wrap" v-if="stationData">
      <transition name="comp-anim">
        <component
          :is="currentComponent"
          :stationData="stationData"
          :sound="sound"
          @navigate="navigate"
          @setName="setName"
          @setLang="setLang"
          @setAvatar="setAvatar"
          @end="end"
        >
        </component>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Howl, Howler } from 'howler';
import Start from './components/Start.vue';
import Intro from './components/Intro.vue';
import AudioIntro from './components/AudioIntro.vue';
import NameForm from './components/NameForm.vue';
import Avatar from './components/Avatar.vue';

import './assets/scss/_global.scss';

export default {
  name: 'app',
  data: () => {
    return {
      currentComponent: 'Start',
      lang: 'de',
      username: 'Batman',
      avatar: null,
      showHero: false,
      stationData: null,
      sound: null,
    };
  },
  components: {
    Start,
    Intro,
    AudioIntro,
    NameForm,
    Avatar,
  },
  mounted() {
    window.setTimeout(() => {
      this.showHero = true;
    }, 500);
    this.loadData().then(() => {});
  },
  computed: {
    showHeader() {
      if (this.currentComponent == 'Start') return false;
      if (this.currentComponent == 'Intro') return false;
      if (this.currentComponent == 'AudioIntro') return false;
      return true;
    },
  },
  methods: {
    navigate(view) {
      this.currentComponent = view;
    },
    setName(name) {
      this.username = name;
    },
    setLang(lang) {
      this.lang = lang;
      this.loadData();
    },
    setAvatar(avatar) {
      this.avatar = avatar;
    },
    loadData() {
      let apiUrl = process.env.VUE_APP_WP_URL + '/wp-json/wp/v2/stations?slug=onboarding-station&lang=de';
      if(this.lang == 'fr') {
        apiUrl = process.env.VUE_APP_WP_URL + '/wp-json/wp/v2/stations?slug=onboarding-station&lang=fr';
      }
      return new Promise((resolve, reject) => {
        axios.get(apiUrl).then((response) => {
          this.stationData = response.data[0];
          resolve();
        });
      });
    },
    createUser() {
      let url = process.env.VUE_APP_BACKEND_URL + '/api/visitors';

      return new Promise((resolve, reject) => {
        axios
          .post(url, {
            name: this.username,
            lang: this.lang,
            avatar: this.avatar,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    end() {
      this.createUser().then((data) => {
        let jsonData = JSON.stringify({
          type: 'userId',
          data: {
            userid: data.data.id,
            userlang: data.data.lang
          },
        });
        window.postMessage(jsonData);
      });
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/fonts';
@import './assets/scss/helpers';

#app {
  font-family: 'GT Sectra', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  &::after {
    height: 700%;
    background: linear-gradient(
      0deg,
      #abeadd,
      #b4efce,
      #b6dcf5,
      #ddc6e8,
      #f9c2b8,
      #f7d3ad,
      #fbeba1
    );
    background-size: 100%;
    transform: translateY(0);
    will-change: transform;
    animation: bggradient 30s linear infinite;
    z-index: 0;
  }
}

.component-wrap {
  z-index: 2;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  text-align: center;
  line-height: 3rem;
  z-index: 2;
  background: var(--white);
}

@keyframes bggradient {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-85%);
  }
  100% {
    transform: translateY(0);
  }
}

.comp-anim-enter-active {
  transition: opacity 1s $easeOutQuint, transform 1s $easeOutQuint;
}
.comp-anim-leave-active {
  transition: opacity 1s $easeOutQuint, transform 1s $easeOutQuint;
}
.comp-anim-enter,
.comp-anim-leave-to {
  opacity: 1;
}
.comp-anim-leave-to {
  transform: translateX(-100%);
}
.comp-anim-enter {
  transform: translateX(100%);
}
</style>
