import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import msg from './messages';

Vue.config.productionTip = false;

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'de',
  messages: msg
});

new Vue({
  i18n,
  render: h => h(App)
}).$mount('#app');
