<template>
  <div class="nameform">
    <div class="content">
      <input
        class="input-name"
        type="text"
        placeholder="Name"
        v-model="username"
      />
      <div class="btn-big" @click.prevent="setName">{{ $t('next') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NameForm",
  props: [],
  data: () => {
    return {
      username: "",
    };
  },
  methods: {
    setName() {
      // console.log(this.username);
      if (!this.username) return;
      this.$emit("navigate", "Avatar");
      this.$emit("setName", this.username);
    },
  },
};
</script>

<style scoped lang="scss">
.nameform {
}
.content {
  padding: 4rem;
  padding-top: 10rem;
}
.input-name {
  width: 100%;
  background-color: transparent;
  font-size: 2rem;
  font-family: "GT Sectra", Helvetica, Arial, sans-serif;
  border: none;
  border-bottom: 3px solid var(--black);
  padding: 0.5rem;
  margin-bottom: 6rem;

  &::placeholder {
    color: rgba(#000, 0.3);
  }
  &:focus {
    background-color: rgba(#fff, 0.2);
    outline: none;
  }
}
</style>
