<template>
  <div ref="intro" class="intro">
    <button class="intro__skip" @click="skip"></button>
    <canvas ref="oscilloscope" class="intro__canvas oscilloscope"></canvas>
  </div>
</template>

<script>
export default {
  name: 'AudioIntro',
  props: ['stationData'],
  data: () => {
    return {
      sound: null,
      currentWaveform: [],
    };
  },
  methods: {
    handleEnd() {
      window.setTimeout(() => {
        this.$emit('navigate', 'NameForm');
      }, 500);
    },
    skip() {
      this.sound.stop();
      this.$emit('navigate', 'NameForm');
    },
    initWaves() {
      const analyser = Howler.ctx.createAnalyser();

      Howler.masterGain.connect(analyser);

      const waveform = new Float32Array(analyser.frequencyBinCount);
      analyser.getFloatTimeDomainData(waveform);
      analyser.smoothingTimeConstant = 1;

      const scale = window.devicePixelRatio;
      const $intro = this.$refs.intro;
      const $canvas = this.$refs.oscilloscope;

      const ctx = $canvas.getContext('2d');
      const rect = $intro.getBoundingClientRect();

      $canvas.width = rect.width * scale;
      $canvas.height = rect.height * scale;

      ctx.scale(scale, scale);
      ctx.fillStyle = '#FFFDF9';

      const waveScaleRatio = rect.width / waveform.length;
      const zoomFactor = 8;

      const draw = (waveform) => {
        requestAnimationFrame(() => {
          draw(waveform);
        });
        analyser.getFloatTimeDomainData(waveform);
        ctx.clearRect(0, 0, $canvas.width, $canvas.height);

        ctx.beginPath();
        ctx.moveTo(0, 0);
        let lastX = 0;

        for (let i = 0; i < waveform.length; i++) {
          if (!this.currentWaveform[i]) this.currentWaveform[i] = 0;
          this.currentWaveform[i] +=
            (waveform[i] - this.currentWaveform[i]) / 7;
          const x = i * waveScaleRatio * zoomFactor;
          const y = (0.4 + this.currentWaveform[i] * 0.7) * window.innerHeight;
          ctx.lineTo(x, y);
          lastX = x;
        }

        ctx.lineTo(lastX, 0);
        ctx.fill();
      };

      draw(waveform);
    },
  },
  mounted() {
    this.sound = new Howl({
      src: [this.stationData.intro_track],
      autoplay: false,
      onend: this.handleEnd,
    });

    window.setTimeout(() => {
      this.sound.play();
    }, 500);

    this.initWaves();
  },
};
</script>

<style scoped lang="scss">
.intro {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.intro__skip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  font-size: 1rem;
  font-family: 'Syntax';
  opacity: 0.15;
  z-index: 1;
  border: none;
  background: none;

  &:focus {
    outline: none;
  }
}
.intro__canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
